// DisabledUsers.jsx

import React, { useRef, useState, useEffect, useMemo } from 'react';
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    CardHeader,
    Col,
    Container,
    Modal,
    ModalBody,
    ModalFooter,
    Row,
    ModalHeader,
    FormGroup,
    Label,
    Input,
} from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import SimpleBar from 'simplebar-react';
import { Link } from 'react-router-dom';
import { ThreeCircles } from 'react-loader-spinner';
import Flatpickr from "react-flatpickr";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ReactPaginate from 'react-paginate';

import firebase from "firebase/compat/app";
import "firebase/firestore";
import "firebase/auth";
import 'firebase/storage';
import { serverTimestamp, arrayUnion, arrayRemove } from 'firebase/firestore';
import { getDownloadURL, uploadBytesResumable, getStorage, ref } from "firebase/storage";

const DisabledUsers = () => {

    const storage = getStorage();

    // ---------------------------
    // STATE VARIABLES
    // ---------------------------
    const [initialLoad, setInitialLoad] = useState(true);
    const [disabledUsersList, setDisabledUsersList] = useState([]);
    const [fetchedData, setFetchedData] = useState(false);
    const [isUploading, setIsUploading] = useState(false);

    // Partner access checkboxes
    const [partnerAccessCreate, setPartnerAccessCreate] = useState(false);
    const handleCreateCheckboxChange = (event) => {
        setPartnerAccessCreate(event.target.checked);
    };

    const [partnerAccessUpdate, setPartnerAccessUpdate] = useState(false);
    const handleUpdateCheckboxChange = (event) => {
        setPartnerAccessUpdate(event.target.checked);
    };

    // Action buttons states
    const [isCreating, setIsCreating] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [updateUserId, setUpdateUserId] = useState('');
    const [updateUserIndex, setUpdateUserIndex] = useState(null);
    const [enableUserId, setEnableUserId] = useState('');
    const [enableUserIndex, setEnableUserIndex] = useState(null);
    const [isActivating, setIsActivating] = useState(false);
    const [updatedImage, setUpdatedImage] = useState(null);
    const [uploadedImage, setUploadedImage] = useState(null);

    const [allTagPrograms, setAllTagPrograms] = useState([]);

    // Date Filters
    const [startDateFilter, setStartDateFilter] = useState(null);
    const [endDateFilter, setEndDateFilter] = useState(null);

    // Modal Visibility States
    const [createModal, setCreateModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [activateModal, setActivateModal] = useState(false);
    const [modal_list, setmodal_list] = useState(false); // Preserved existing state

    // Reference for Firestore Listener
    const subscriptionRef = useRef(null);

    // ---------------------------
    // DOCUMENT TITLE
    // ---------------------------
    useEffect(() => {
        document.title = "Registrations | Fish-Trak - Internal Management Dashboard";
    }, []);

    // ---------------------------
    // FETCH TAG PROGRAMS
    // ---------------------------
    const fetchTagPrograms = () => {
        firebase
            .firestore()
            .collection('programs')
            .orderBy('created_at', 'desc')
            .onSnapshot(
                querySnapshot => {
                    const newProgramsList = [];
                    querySnapshot.forEach(doc => {
                        const programItem = doc.data();
                        programItem.id = doc.id;

                        programItem.name = programItem.program_name;
                        programItem.region = programItem.program_region;
                        programItem.country = programItem.country_code;
                        programItem.type = programItem.program_type;
                        programItem.length = programItem.tag_length;
                        programItem.image = programItem.image_url;

                        const createdAt = programItem && programItem.created_at
                            ? programItem.created_at.toDate().toLocaleString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })
                            : '';
                        programItem.created = createdAt;
                        const updatedAt = programItem && programItem.updated_at
                            ? programItem.updated_at.toDate().toLocaleString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })
                            : '';
                        programItem.updated = updatedAt;

                        newProgramsList.push(programItem);
                    });
                    setAllTagPrograms(newProgramsList);
                },
                error => {
                    console.log("Error fetching tag programs:", error);
                }
            );
    };

    // ---------------------------
    // FETCH DISABLED USERS WITH FILTERS
    // ---------------------------
    const fetchDisabledUsers = () => {
        // Unsubscribe previous listener if any
        if (subscriptionRef.current) {
            subscriptionRef.current();
        }

        // Build Firestore query
        let newQuery = firebase.firestore()
            .collection('users')
            .where('active', '==', false)
            .orderBy('created_at', 'desc');

        // Apply date filters
        if (startDateFilter && endDateFilter) {
            newQuery = newQuery
                .where('created_at', '>=', firebase.firestore.Timestamp.fromDate(new Date(startDateFilter)))
                .where('created_at', '<=', firebase.firestore.Timestamp.fromDate(new Date(endDateFilter)));
        }
        else if (startDateFilter && !endDateFilter) {
            newQuery = newQuery
                .where('created_at', '>=', firebase.firestore.Timestamp.fromDate(new Date(startDateFilter)));
        }
        else if (!startDateFilter && endDateFilter) {
            newQuery = newQuery
                .where('created_at', '<=', firebase.firestore.Timestamp.fromDate(new Date(endDateFilter)));
        }

        // Listen to real-time updates
        const unsub = newQuery.onSnapshot(
            (querySnapshot) => {
                const newUsersList = [];
                querySnapshot.forEach(doc => {
                    const userItem = doc.data();
                    userItem.id = doc.id;

                    userItem.name = `${userItem.first_name} ${userItem.last_name}`;
                    userItem.email = userItem.email_address;
                    userItem.port = userItem.home_port;
                    const createdAt = userItem && userItem.created_at
                        ? userItem.created_at.toDate().toLocaleString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })
                        : '';
                    userItem.created = createdAt;
                    const updatedAt = userItem && userItem.updated_at
                        ? userItem.updated_at.toDate().toLocaleString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })
                        : '';
                    userItem.updated = updatedAt;
                    userItem.count = userItem.catch_count;
                    userItem.status = userItem.active;
                    userItem.link = `/user/details/${userItem.id}`;
                    userItem.mailtoLink = `mailto:${userItem.email_address}?subject=Fish-Trak Admin`;

                    newUsersList.push(userItem);
                });
                setDisabledUsersList(newUsersList);
                setEditModal(false);
                setIsUploading(false);
                setIsUpdating(false);
                setmodal_list(false);
                setActivateModal(false);
                setIsActivating(false);
                setFetchedData(true);
            },
            (error) => {
                console.error("Error fetching disabled users:", error);
            }
        );

        subscriptionRef.current = unsub;
    };

    // ---------------------------
    // INITIAL DATA FETCHING
    // ---------------------------
    useEffect(() => {
        if (initialLoad) {
            setInitialLoad(false);
            fetchDisabledUsers();
            fetchTagPrograms();
        }

        if (fetchedData && disabledUsersList.length > 0) {
            setFetchedData(false);
            // No need to create list.js
        }
    }, [initialLoad, fetchedData, disabledUsersList]);

    // ---------------------------
    // SEARCH AND FILTER LOGIC
    // ---------------------------
    const [searchQuery, setSearchQuery] = useState('');

    const filteredUsers = useMemo(() => {
        return disabledUsersList.filter(user => {
            const nameMatch = user.name.toLowerCase().includes(searchQuery.toLowerCase());
            const emailMatch = user.email.toLowerCase().includes(searchQuery.toLowerCase());
            const portMatch = user.port.toLowerCase().includes(searchQuery.toLowerCase());
            return nameMatch || emailMatch || portMatch;
        });
    }, [disabledUsersList, searchQuery]);

    // ---------------------------
    // PAGINATION CALCULATIONS
    // ---------------------------
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10;

    const pageCount = Math.ceil(filteredUsers.length / itemsPerPage);
    const displayedUsers = filteredUsers.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

    // ---------------------------
    // HANDLE PAGE CHANGE
    // ---------------------------
    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };

    // ---------------------------
    // HANDLE APPLY FILTERS BUTTON
    // ---------------------------
    const handleApplyFilters = () => {
        fetchDisabledUsers();
        setCurrentPage(0); // Reset to first page when filters are applied
    };

    // ---------------------------
    // HANDLE DOWNLOAD CSV
    // ---------------------------
    const handleDownloadCSV = async () => {
        try {
            let csvQuery = firebase.firestore()
                .collection('users')
                .where('active', '==', false)
                .orderBy('created_at', 'desc');

            // Apply date filters
            if (startDateFilter && endDateFilter) {
                csvQuery = csvQuery
                    .where('created_at', '>=', firebase.firestore.Timestamp.fromDate(new Date(startDateFilter)))
                    .where('created_at', '<=', firebase.firestore.Timestamp.fromDate(new Date(endDateFilter)));
            }
            else if (startDateFilter && !endDateFilter) {
                csvQuery = csvQuery
                    .where('created_at', '>=', firebase.firestore.Timestamp.fromDate(new Date(startDateFilter)));
            }
            else if (!startDateFilter && endDateFilter) {
                csvQuery = csvQuery
                    .where('created_at', '<=', firebase.firestore.Timestamp.fromDate(new Date(endDateFilter)));
            }

            const snapshot = await csvQuery.get();
            const csvRows = [];
            // CSV header
            csvRows.push([
                'User ID',
                'First Name',
                'Last Name',
                'Email',
                'Home Port',
                'Catch Count',
                'Created Date',
                'Created Time',
                'Updated Date',
                'Updated Time'
            ].join(','));

            snapshot.forEach(doc => {
                const data = doc.data();
                const createdAt = data.created_at
                    ? data.created_at.toDate().toLocaleString()
                    : '';
                const updatedAt = data.updated_at
                    ? data.updated_at.toDate().toLocaleString()
                    : '';
                const row = [
                    doc.id,
                    (data.first_name || '').replace(/,/g, ''),
                    (data.last_name || '').replace(/,/g, ''),
                    (data.email_address || '').replace(/,/g, ''),
                    (data.home_port || '').replace(/,/g, ''),
                    typeof data.catch_count !== 'undefined' ? data.catch_count : 0,
                    createdAt,
                    updatedAt
                ];
                csvRows.push(row.join(','));
            });

            const csvString = csvRows.join('\n');
            const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'filtered_disabled_users.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        } catch (error) {
            console.error('Error generating CSV:', error);
            toast.error('Could not download CSV. Please try again.');
        }
    };

    // ---------------------------
    // CRUD OPERATIONS
    // ---------------------------

    // Handle Create User Form Submission
    const handleCreateUser = (event) => {
        event.preventDefault();
        submitCreateForm(event);
    };

    const submitCreateForm = async (event) => {
        setIsUploading(true);
        setIsCreating(true);
        event.preventDefault();

        if (uploadedImage) {
            await uploadImage(event);
        }
        else {
            await createUser(event);
        }
    };

    const uploadImage = async (event) => {
        event.preventDefault();
        const emailAddress = event.target.email_address.value.replace(/[^a-zA-Z0-9+-_~!#$%&'./=^|{}"]/g, '');
        const timeStamp = Date.now();

        const imageFileName = `user-avatars/${emailAddress}-${timeStamp}`;

        const storageRef = ref(storage, imageFileName);

        const uploadTask = uploadBytesResumable(storageRef, uploadedImage);
        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const progress =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log("Upload is " + progress + "% done");
            },
            (error) => {
                console.log(error);
                setIsUploading(false);
                setIsCreating(false);
                toast.error('Could not upload user avatar. Please try again.');
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadedURL) => {
                    createUser(event, downloadedURL);
                });
            }
        );
    };

    const generateRandomPassword = () => {
        const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()";
        const length = 12;
        return Array.from({ length }, () => charset[Math.floor(Math.random() * charset.length)]).join("");
    };

    const createUser = async (event, downloadedURL = null) => {
        setIsUploading(false);
        setIsCreating(true);

        const emailAddress = event.target.email_address.value.replace(/[^a-zA-Z0-9+-_~!#$%&@'./=^|{}"]/g, '');
        const userPassword = generateRandomPassword();
        const locationGeo = new firebase.firestore.GeoPoint(
            parseFloat(event.target.home_port_lat.value.replace(/[^0-9.-]/g, '')),
            parseFloat(event.target.home_port_lng.value.replace(/[^0-9.-]/g, ''))
        );

        const avatarPlaceholderImage = 'https://firebasestorage.googleapis.com/v0/b/fish-trak-app.appspot.com/o/program-logos%2Ffish-trak-program-logo.png?alt=media&token=8ef8bf14-1c21-4dd1-8ffc-6f860f41dfa6';

        try {
            const userCredential = await firebase.auth().createUserWithEmailAndPassword(emailAddress, userPassword);
            await userCredential.user.sendEmailVerification();
            const uid = userCredential.user.uid;

            // Prepare user data
            const userData = {
                id: uid,
                account_type: 1,
                active: false, // Since this is DisabledUsers
                approved: true,
                verified: false,
                onboarded: false,
                catch_count: 0,
                created_at: serverTimestamp(),
                recatch_count: 0,
                species_count: 0,
                signup_location: locationGeo,
                text_messages: true,
                updated_at: serverTimestamp(),
                privacy_mode: false,
                home_port_geo: locationGeo,
                email_address: emailAddress,
                email_updates: true,
                text_messages: true,
                recatch_alerts: true,
                notifications: true,

                first_name: event.target.first_name.value.replace(/[^a-zA-Z&-,.' ]/g, ''),
                last_name: event.target.last_name.value.replace(/[^a-zA-Z&-,.' ]/g, ''),
                mobile_number: event.target.mobile_number.value.replace(/[^0-9+]/g, ''),
                home_port: event.target.home_port.value.replace(/[^a-zA-Z&()-+',./' ]/g, ''),
                country_code: event.target.user_country.value.replace(/[^a-zA-Z]/g, ''),
                metric_system: JSON.parse(event.target.units.value),
                admin_user: event.target.admin_access.checked,
                partner_user: event.target.partner_access.checked,
                image_url: downloadedURL ? downloadedURL : avatarPlaceholderImage
            };

            // If Partner Access is enabled, assign program_access
            if (userData.partner_user && event.target.tag_program.value) {
                userData.program_access = firebase.firestore().collection('programs').doc(event.target.tag_program.value);
            }

            // Add user to Firestore
            await firebase.firestore().collection('users').doc(uid).set(userData);

            // Send Password Reset Email
            await firebase.auth().sendPasswordResetEmail(emailAddress);

            toast.success('The user has been created successfully!');
            fetchDisabledUsers();
            setCreateModal(false);
            setIsCreating(false);
        } catch (error) {
            console.error("Error creating user:", error);
            setIsUploading(false);
            setIsCreating(false);
            if (error.code === 'auth/email-already-in-use') {
                toast.error('That email address is already registered. Please use a different email.');
            }
            else {
                toast.error('Could not create account. Please try again.');
            }
        }
    };

    // ---------------------------
    // Handle Update User Form Submission
    // ---------------------------
    const handleUpdateUser = (event) => {
        event.preventDefault();
        submitUpdateForm(event);
    };

    const submitUpdateForm = async (event) => {
        setIsUploading(true);
        setIsUpdating(true);
        event.preventDefault();

        if (updatedImage) {
            await updateImage(event);
        }
        else {
            await updateUser(event);
        }
    };

    const updateImage = async (event) => {
        event.preventDefault();
        const imageFileName = `user-avatars/${disabledUsersList[updateUserIndex]?.id}`;
        const storageRef = ref(storage, imageFileName);

        const uploadTask = uploadBytesResumable(storageRef, updatedImage);
        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const progress =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log("Upload is " + progress + "% done");
            },
            (error) => {
                console.log(error);
                setIsUpdating(false);
                setIsUploading(false);
                toast.error('Could not update user avatar. Please try again.');
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadedURL) => {
                    updateUser(event, downloadedURL);
                });
            }
        );
    };

    const updateUser = async (event, downloadedURL = null) => {
        // Prepare user data
        const userData = {
            updated_at: serverTimestamp(),
        };

        // User information
        if (event.target.first_name.value !== disabledUsersList[updateUserIndex]?.first_name) {
            userData['first_name'] = event.target.first_name.value.replace(/[^a-zA-Z&-,.' ]/g, '');
        }
        if (event.target.last_name.value !== disabledUsersList[updateUserIndex]?.last_name) {
            userData['last_name'] = event.target.last_name.value.replace(/[^a-zA-Z&-,.' ]/g, '');
        }

        // Contact details
        if (event.target.email_address.value !== disabledUsersList[updateUserIndex]?.email_address) {
            userData['email_address'] = event.target.email_address.value.replace(/[^a-zA-Z0-9+-_~!#$%&@'./=^|{}"]/g, '');
        }
        if (event.target.mobile_number.value !== disabledUsersList[updateUserIndex]?.mobile_number) {
            userData['mobile_number'] = event.target.mobile_number.value.replace(/[^0-9+]/g, '');
        }

        // Location data
        if (event.target.home_port.value !== disabledUsersList[updateUserIndex]?.home_port) {
            userData['home_port'] = event.target.home_port.value.replace(/[^a-zA-Z&()-+',./' ]/g, '');
        }
        if (
            parseFloat(event.target.home_port_lat.value) !== disabledUsersList[updateUserIndex]?.home_port_geo?.latitude ||
            parseFloat(event.target.home_port_lng.value) !== disabledUsersList[updateUserIndex]?.home_port_geo?.longitude
        ) {
            userData['home_port_geo'] = new firebase.firestore.GeoPoint(
                parseFloat(event.target.home_port_lat.value.replace(/[^0-9.-]/g, '')),
                parseFloat(event.target.home_port_lng.value.replace(/[^0-9.-]/g, ''))
            );
        }
        if (event.target.user_country.value !== disabledUsersList[updateUserIndex]?.country_code) {
            userData['country_code'] = event.target.user_country.value.replace(/[^a-zA-Z]/g, '');
        }

        // Metric counts 
        if (parseInt(event.target.catch_count.value) !== disabledUsersList[updateUserIndex]?.catch_count) {
            userData['catch_count'] = parseInt(event.target.catch_count.value) || 0;
        }
        if (parseInt(event.target.recatch_count.value) !== disabledUsersList[updateUserIndex]?.recatch_count) {
            userData['recatch_count'] = parseInt(event.target.recatch_count.value) || 0;
        }
        if (parseInt(event.target.species_count.value) !== disabledUsersList[updateUserIndex]?.species_count) {
            userData['species_count'] = parseInt(event.target.species_count.value) || 0;
        }

        // Account Status
        if (event.target.units.value !== String(disabledUsersList[updateUserIndex]?.metric_system)) {
            userData['metric_system'] = JSON.parse(event.target.units.value);
        }
        if (event.target.active.checked !== disabledUsersList[updateUserIndex]?.active) {
            userData['active'] = event.target.active.checked;
        }
        if (event.target.approved.checked !== disabledUsersList[updateUserIndex]?.approved) {
            userData['approved'] = event.target.approved.checked;
        }
        if (event.target.onboarded.checked !== disabledUsersList[updateUserIndex]?.onboarded) {
            userData['onboarded'] = event.target.onboarded.checked;
        }
        if (event.target.verified.checked !== disabledUsersList[updateUserIndex]?.verified) {
            userData['verified'] = event.target.verified.checked;
        }

        // Notifications
        if (event.target.email_updates.checked !== disabledUsersList[updateUserIndex]?.email_updates) {
            userData['email_updates'] = event.target.email_updates.checked;
        }
        if (event.target.text_messages.checked !== disabledUsersList[updateUserIndex]?.text_messages) {
            userData['text_messages'] = event.target.text_messages.checked;
        }
        if (event.target.recatch_alerts.checked !== disabledUsersList[updateUserIndex]?.recatch_alerts) {
            userData['recatch_alerts'] = event.target.recatch_alerts.checked;
        }
        if (event.target.push_notifications.checked !== disabledUsersList[updateUserIndex]?.notifications) {
            userData['notifications'] = event.target.push_notifications.checked;
        }

        // Permissions
        if (event.target.admin_access.checked !== disabledUsersList[updateUserIndex]?.admin_user) {
            userData['admin_user'] = event.target.admin_access.checked;
        }
        if (event.target.partner_access.checked !== disabledUsersList[updateUserIndex]?.partner_user) {
            userData['partner_user'] = event.target.partner_access.checked;
        }
        if (event.target.privacy_mode.checked !== disabledUsersList[updateUserIndex]?.privacy_mode) {
            userData['privacy_mode'] = event.target.privacy_mode.checked;
        }

        // Program Access
        if (partnerAccessUpdate) {
            if (event.target.tag_program.value !== (disabledUsersList[updateUserIndex]?.program_access?.id || '')) {
                userData['program_access'] = firebase.firestore().collection('programs').doc(event.target.tag_program.value);
            }
        } else {
            userData['program_access'] = null;
        }

        // New Image URL
        if (downloadedURL) {
            userData['image_url'] = downloadedURL;
        }

        try {
            const userRef = firebase.firestore().collection('users').doc(disabledUsersList[updateUserIndex]?.id);
            await userRef.update(userData);

            // Handle program_access updates
            if (partnerAccessUpdate) {
                if (disabledUsersList[updateUserIndex]?.program_access && event.target.tag_program.value !== disabledUsersList[updateUserIndex]?.program_access.id) {
                    // Remove from old program
                    await disabledUsersList[updateUserIndex]?.program_access.update({
                        program_managers: arrayRemove(userRef)
                    });
                    // Add to new program
                    const newProgramRef = firebase.firestore().collection('programs').doc(event.target.tag_program.value);
                    await newProgramRef.update({
                        program_managers: arrayUnion(userRef)
                    });
                } else if (!disabledUsersList[updateUserIndex]?.program_access && event.target.tag_program.value) {
                    // Add to new program
                    const newProgramRef = firebase.firestore().collection('programs').doc(event.target.tag_program.value);
                    await newProgramRef.update({
                        program_managers: arrayUnion(userRef)
                    });
                }
            } else if (disabledUsersList[updateUserIndex]?.program_access && !partnerAccessUpdate) {
                // Remove from old program
                await disabledUsersList[updateUserIndex]?.program_access.update({
                    program_managers: arrayRemove(userRef)
                });
            }

            toast.success('The user has been updated successfully!');
            fetchDisabledUsers();
            setIsUpdating(false);
            setIsUploading(false);
            setEditModal(false);
        } catch (error) {
            console.error("Error updating user:", error);
            setIsUpdating(false);
            setIsUploading(false);
            toast.error('Could not update user entry. Please try again.');
        }
    };

    // ---------------------------
    // HANDLE ENABLE USER
    // ---------------------------
    const enableUser = async () => {
        setIsActivating(true);

        try {
            const userRef = firebase.firestore().collection('users').doc(disabledUsersList[enableUserIndex]?.id);
            await userRef.update({ active: true });
            toast.success('The user account has been activated successfully!');
            fetchDisabledUsers();
            setEnableUserId('');
            setEnableUserIndex(null);
            setIsActivating(false);
            setActivateModal(false);
        } catch (error) {
            console.error("Error enabling user:", error);
            setIsActivating(false);
            toast.error('Could not activate user. Please try again.');
        }
    };

    // ---------------------------
    // HANDLE EDIT USER MODAL TOGGLE
    // ---------------------------
    const openEditModal = (userId, userIndex) => {
        setUpdateUserId(userId);
        setUpdateUserIndex(userIndex);
        if (disabledUsersList[userIndex] && disabledUsersList[userIndex].partner_user) {
            setPartnerAccessUpdate(disabledUsersList[userIndex].partner_user);
        } else {
            setPartnerAccessUpdate(false);
        }
        setEditModal(true);
    };

    const closeEditModal = () => {
        setUpdateUserId('');
        setUpdateUserIndex(null);
        setUpdatedImage(null);
        setPartnerAccessUpdate(false);
        setEditModal(false);
    };

    // ---------------------------
    // HANDLE ACTIVATE USER MODAL TOGGLE
    // ---------------------------
    const openActivateModal = (userId, userIndex) => {
        setEnableUserId(userId);
        setEnableUserIndex(userIndex);
        setActivateModal(true);
    };

    const closeActivateModal = () => {
        setEnableUserId('');
        setEnableUserIndex(null);
        setActivateModal(false);
    };

    // ---------------------------
    // HANDLE CREATE USER MODAL TOGGLE
    // ---------------------------
    const openCreateModal = () => {
        setCreateModal(true);
    };

    const closeCreateModal = () => {
        setCreateModal(false);
        setPartnerAccessCreate(false);
        setUploadedImage(null);
    };

    // ---------------------------
    // SEARCH AND FILTER EFFECTS
    // ---------------------------
    useEffect(() => {
        if (fetchedData && disabledUsersList.length > 0) {
            setFetchedData(false);
        }
    }, [fetchedData, disabledUsersList]);

    // ---------------------------
    // COMPONENT RETURN
    // ---------------------------
    return (
        <React.Fragment>
            <ToastContainer />
            <div className="page-content">
                <Container fluid>

                    <CardTitle>Registrations</CardTitle>

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title mb-0">Disabled Users</h4>
                                </CardHeader>

                                <CardBody>
                                    {/* Filters and Actions */}
                                    <Row className="g-4 mb-3">
                                        <Col sm="auto">
                                            <div className="d-flex gap-1">
                                                <Button
                                                    color="success"
                                                    onClick={openCreateModal}
                                                    id="create-btn"
                                                >
                                                    <i className="ri-add-line align-bottom me-1"></i> Add New User
                                                </Button>
                                            </div>
                                        </Col>
                                        <Col className="d-flex align-items-start">
                                                <Input
                                                    type="search"
                                                    name="search"
                                                    id="search"
                                                    placeholder="Search Users..."
                                                    value={searchQuery}
                                                    onChange={(e) => setSearchQuery(e.target.value)}
                                                />
                                        </Col>
                                        <Col sm="2" md="2">
                                                <Flatpickr
                                                    id="start-date"
                                                    className="form-control"
                                                    options={{ dateFormat: "Y-m-d", maxDate: endDateFilter ? endDateFilter : undefined }}
                                                    placeholder="Start Date"
                                                    value={startDateFilter}
                                                    onChange={(dates) => setStartDateFilter(dates[0] || null)}
                                                />
                                        </Col>
                                        <Col sm="2" md="2">
                                                <Flatpickr
                                                    id="end-date"
                                                    className="form-control"
                                                    options={{ dateFormat: "Y-m-d", minDate: startDateFilter ? startDateFilter : undefined }}
                                                    placeholder="End Date"
                                                    value={endDateFilter}
                                                    onChange={(dates) => setEndDateFilter(dates[0] || null)}
                                                />
                                        </Col>
                                        <Col sm="1" md="1" className="d-flex align-items-end">
                                            <Button
                                                color="secondary"
                                                onClick={handleApplyFilters}
                                                style={{ width: '100%' }}
                                            >
                                                <i className="mdi mdi-filter"></i> 
                                            </Button>
                                        </Col>
                                        <Col sm="1" md="1" className="d-flex align-items-end">
                                            <Button
                                                color="primary"
                                                onClick={handleDownloadCSV}
                                                style={{ width: '100%' }}
                                            >
                                                <i className="mdi mdi-download"></i> 
                                            </Button>
                                        </Col>
                                    </Row>

                                    {/* Users Table */}
                                    <div className="table-responsive table-card mt-3 mb-1">
                                        {(!isUpdating && !isActivating && !isCreating) && (
                                            <table className="table align-middle table-nowrap">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th scope="col" style={{ width: "50px" }}>View</th>
                                                        <th scope="col">Image</th>
                                                        <th scope="col">User Name</th>
                                                        <th scope="col">Email Address</th>
                                                        <th scope="col">Home Port</th>
                                                        <th scope="col">Catches</th>
                                                        <th scope="col">Created Date</th>
                                                        <th scope="col">Last Active</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {displayedUsers.length > 0 ? displayedUsers.map((user, index) => {
                                                        const actualIndex = currentPage * itemsPerPage + index;
                                                        return (
                                                            <tr key={user.id}>
                                                                <th scope="row">
                                                                    <Link to={user.link} className="btn btn-sm btn-success" title="View User">
                                                                        <i className="mdi mdi-eye-outline"></i>
                                                                    </Link>
                                                                </th>
                                                                <td>
                                                                    {user.image_url ? (
                                                                        <img
                                                                            className="avatar-sm rounded-circle"
                                                                            alt={user.name}
                                                                            src={user.image_url}
                                                                        />
                                                                    ) : (
                                                                        <span>No Image</span>
                                                                    )}
                                                                </td>
                                                                <td>{user.name}</td>
                                                                <td>
                                                                    <a href={user.mailtoLink} className="email-link">
                                                                        {user.email}
                                                                    </a>
                                                                </td>
                                                                <td>{user.port}</td>
                                                                <td>
                                                                    <span className="badge badge-soft-success text-uppercase">
                                                                        {user.count}
                                                                    </span>
                                                                </td>
                                                                <td>{user.created}</td>
                                                                <td>{user.updated}</td>
                                                                <td>
                                                                    {user.status ? (
                                                                        <span className="badge badge-soft-success text-uppercase">Active</span>
                                                                    ) : (
                                                                        <span className="badge badge-soft-danger text-uppercase">Inactive</span>
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    <div className="d-flex gap-2">
                                                                        {/* Email Button */}
                                                                        <Button
                                                                            color="primary"
                                                                            size="sm"
                                                                            onClick={() => window.location.href = user.mailtoLink}
                                                                            title="Email User"
                                                                        >
                                                                            <i className="mdi mdi-email-send"></i>
                                                                        </Button>
                                                                        {/* Edit Button */}
                                                                        <Button
                                                                            color="warning"
                                                                            size="sm"
                                                                            onClick={() => openEditModal(user.id, actualIndex)}
                                                                            title="Edit User"
                                                                        >
                                                                            <i className="mdi mdi-square-edit-outline"></i>
                                                                        </Button>
                                                                        {/* Activate Button */}
                                                                        {!user.active && (
                                                                            <Button
                                                                                size="sm"
                                                                                style={{ backgroundColor: "green" }} 
                                                                                onClick={() => openActivateModal(user.id, actualIndex)}
                                                                                title="Activate User"
                                                                            >
                                                                                <i className="mdi mdi-account-check-outline"></i>
                                                                            </Button>
                                                                        )}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }) : (
                                                        <tr>
                                                            <td colSpan="10" className="text-center">No Disabled Users Found!</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        )}

                                        {/* Loader */}
                                        {(isUpdating || isActivating || isCreating) && (
                                            <div className="d-flex justify-content-center my-5">
                                                <ThreeCircles
                                                    visible={isUpdating || isActivating || isCreating}
                                                    height="100"
                                                    width="100"
                                                    color="#4192C3"
                                                    ariaLabel="three-circles-loading"
                                                    wrapperStyle={{ margin: "0 auto" }}
                                                    wrapperClass="justify-content-center"
                                                />
                                            </div>
                                        )}

                                        {/* No Results Message */}
                                        {filteredUsers.length === 0 && !isUpdating && !isActivating && !isCreating && (
                                            <div className="noresult">
                                                <div className="text-center">
                                                    <h5 className="mt-2">No Disabled Users Found!</h5>
                                                    <p className="text-muted mb-0">We could not find any disabled users.</p>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    {/* End Users Table */}

                                    {/* Pagination Controls */}
                                    {pageCount > 1 && (
                                        <div className="d-flex justify-content-end">
                                            <ReactPaginate
                                                previousLabel={"← Previous"}
                                                nextLabel={"Next →"}
                                                pageCount={pageCount}
                                                onPageChange={handlePageChange}
                                                containerClassName={"pagination"}
                                                previousLinkClassName={"page-link"}
                                                nextLinkClassName={"page-link"}
                                                disabledClassName={"disabled"}
                                                activeClassName={"active"}
                                                pageLinkClassName={"page-link"}
                                                breakLabel={"..."}
                                                breakClassName={"page-item"}
                                                breakLinkClassName={"page-link"}
                                            />
                                        </div>
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* --------------------------- */}
            {/* Modals */}
            {/* --------------------------- */}

            {/* Edit Modal */}
            <Modal isOpen={editModal} toggle={closeEditModal} centered>
                {!isUpdating && disabledUsersList[updateUserIndex] && (
                    <form className="tablelist-form" onSubmit={handleUpdateUser}>
                        <ModalHeader toggle={closeEditModal}>Edit User</ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Label htmlFor="first-name">First Name*</Label>
                                <Input
                                    type="text"
                                    name="first_name"
                                    id="first-name"
                                    placeholder="Richard"
                                    required
                                    defaultValue={disabledUsersList[updateUserIndex]?.first_name || ''}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="last-name">Last Name*</Label>
                                <Input
                                    type="text"
                                    name="last_name"
                                    id="last-name"
                                    placeholder="Ryan"
                                    required
                                    defaultValue={disabledUsersList[updateUserIndex]?.last_name || ''}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="email">Email Address*</Label>
                                <Input
                                    type="email"
                                    name="email_address"
                                    id="email"
                                    placeholder="rich@fish-trak.com"
                                    required
                                    defaultValue={disabledUsersList[updateUserIndex]?.email_address || ''}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="mobile-number">Mobile Number*</Label>
                                <Input
                                    type="tel"
                                    name="mobile_number"
                                    id="mobile-number"
                                    placeholder="+14151234567"
                                    required
                                    defaultValue={disabledUsersList[updateUserIndex]?.mobile_number || ''}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="avatar">User Avatar*</Label>
                                <Row>
                                    <Col xs="4">
                                        {disabledUsersList[updateUserIndex]?.image_url ? (
                                            <img
                                                className="avatar-sm rounded-circle"
                                                alt={disabledUsersList[updateUserIndex]?.name}
                                                src={disabledUsersList[updateUserIndex]?.image_url}
                                            />
                                        ) : (
                                            <span>No Image</span>
                                        )}
                                    </Col>
                                    <Col xs="8">
                                        <Input
                                            type="file"
                                            name="image_link"
                                            id="avatar"
                                            className="form-control"
                                            onChange={(e) => { setUpdatedImage(e.target.files[0]); }}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="home-port">Home Port Location*</Label>
                                <Input
                                    type="text"
                                    name="home_port"
                                    id="home-port"
                                    placeholder="Toms River"
                                    required
                                    defaultValue={disabledUsersList[updateUserIndex]?.home_port || ''}
                                />
                            </FormGroup>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label htmlFor="home-port-lat">Home Port Latitude*</Label>
                                        <Input
                                            type="number"
                                            name="home_port_lat"
                                            id="home-port-lat"
                                            placeholder="Enter Latitude*"
                                            step="any"
                                            required
                                            defaultValue={disabledUsersList[updateUserIndex]?.home_port_geo?.latitude || ''}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label htmlFor="home-port-lng">Home Port Longitude*</Label>
                                        <Input
                                            type="number"
                                            name="home_port_lng"
                                            id="home-port-lng"
                                            placeholder="Enter Longitude*"
                                            step="any"
                                            required
                                            defaultValue={disabledUsersList[updateUserIndex]?.home_port_geo?.longitude || ''}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <FormGroup>
                                <Label htmlFor="user-country">User Country*</Label>
                                <Input
                                    type="select"
                                    name="user_country"
                                    id="user-country"
                                    required
                                    defaultValue={disabledUsersList[updateUserIndex]?.country_code || ''}
                                >
                                    <option value="">Select User Country</option>
                                    <option value="USA">United States</option>
                                    <option value="CAN">Canada</option>
                                    {/* Add more countries as needed */}
                                </Input>
                            </FormGroup>

                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label>Units System*</Label>
                                        <div>
                                            <FormGroup check inline>
                                                <Label check>
                                                    <Input
                                                        type="radio"
                                                        name="units"
                                                        value="true"
                                                        defaultChecked={disabledUsersList[updateUserIndex]?.metric_system === true}
                                                    />{' '}
                                                    Metric
                                                </Label>
                                            </FormGroup>
                                            <FormGroup check inline>
                                                <Label check>
                                                    <Input
                                                        type="radio"
                                                        name="units"
                                                        value="false"
                                                        defaultChecked={disabledUsersList[updateUserIndex]?.metric_system === false}
                                                    />{' '}
                                                    Imperial
                                                </Label>
                                            </FormGroup>
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <FormGroup>
                                <Label>Account Status*</Label>
                                <div>
                                    <FormGroup check inline>
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                name="active"
                                                defaultChecked={disabledUsersList[updateUserIndex]?.active || false}
                                            />{' '}
                                            Activated
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check inline>
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                name="approved"
                                                defaultChecked={disabledUsersList[updateUserIndex]?.approved || false}
                                            />{' '}
                                            Approved
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check inline>
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                name="onboarded"
                                                defaultChecked={disabledUsersList[updateUserIndex]?.onboarded || false}
                                            />{' '}
                                            Onboarded
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check inline>
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                name="verified"
                                                defaultChecked={disabledUsersList[updateUserIndex]?.verified || false}
                                            />{' '}
                                            Verified
                                        </Label>
                                    </FormGroup>
                                </div>
                            </FormGroup>

                            <FormGroup>
                                <Label>Notifications*</Label>
                                <div>
                                    <FormGroup check inline>
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                name="email_updates"
                                                defaultChecked={disabledUsersList[updateUserIndex]?.email_updates || false}
                                            />{' '}
                                            Email Updates
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check inline>
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                name="text_messages"
                                                defaultChecked={disabledUsersList[updateUserIndex]?.text_messages || false}
                                            />{' '}
                                            Text Messages
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check inline>
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                name="recatch_alerts"
                                                defaultChecked={disabledUsersList[updateUserIndex]?.recatch_alerts || false}
                                            />{' '}
                                            Recatch Alerts
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check inline>
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                name="push_notifications"
                                                defaultChecked={disabledUsersList[updateUserIndex]?.notifications || false}
                                            />{' '}
                                            Push Notifications
                                        </Label>
                                    </FormGroup>
                                </div>
                            </FormGroup>

                            <FormGroup>
                                <Label>Permissions*</Label>
                                <div>
                                    <FormGroup check inline>
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                name="admin_access"
                                                defaultChecked={disabledUsersList[updateUserIndex]?.admin_user || false}
                                            />{' '}
                                            Admin Access
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check inline>
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                name="partner_access"
                                                defaultChecked={disabledUsersList[updateUserIndex]?.partner_user || false}
                                                onChange={handleUpdateCheckboxChange}
                                            />{' '}
                                            Partner Access
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check inline>
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                name="privacy_mode"
                                                defaultChecked={disabledUsersList[updateUserIndex]?.privacy_mode || false}
                                            />{' '}
                                            Privacy Mode
                                        </Label>
                                    </FormGroup>
                                </div>
                            </FormGroup>

                            {partnerAccessUpdate && (
                                <FormGroup>
                                    <Label htmlFor="tag-program">Partner Tag Program*</Label>
                                    <Input
                                        type="select"
                                        name="tag_program"
                                        id="tag-program"
                                        required
                                        defaultValue={disabledUsersList[updateUserIndex]?.program_access?.id || ''}
                                    >
                                        <option value="">Select Tag Program</option>
                                        {allTagPrograms.map((prog) => (
                                            <option key={prog.id} value={prog.id}>
                                                {prog.name}
                                            </option>
                                        ))}
                                    </Input>
                                </FormGroup>
                            )}
                        </ModalBody>
                        <ModalFooter>
                            <div className="hstack gap-2 justify-content-end">
                                <Button
                                    type="button"
                                    color="secondary"
                                    onClick={closeEditModal}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    color="success"
                                    disabled={isUploading}
                                >
                                    Update
                                </Button>
                            </div>
                        </ModalFooter>
                    </form>
                )}
                {isUpdating && (
                    <ModalBody>
                        <div className="d-flex justify-content-center my-5">
                            <ThreeCircles
                                visible={isUpdating}
                                height="100"
                                width="100"
                                color="#4192C3"
                                ariaLabel="three-circles-loading"
                                wrapperStyle={{ margin: "0 auto" }}
                                wrapperClass="justify-content-center"
                            />
                        </div>
                    </ModalBody>
                )}
            </Modal>

            {/* Create Modal */}
            <Modal isOpen={createModal} toggle={closeCreateModal} centered>
                {!isCreating ? (
                    <form className="tablelist-form" onSubmit={handleCreateUser}>
                        <ModalHeader toggle={closeCreateModal}>Create User</ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Label htmlFor="create-first-name">First Name*</Label>
                                <Input
                                    type="text"
                                    name="first_name"
                                    id="create-first-name"
                                    placeholder="Richard"
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="create-last-name">Last Name*</Label>
                                <Input
                                    type="text"
                                    name="last_name"
                                    id="create-last-name"
                                    placeholder="Ryan"
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="create-email">Email Address*</Label>
                                <Input
                                    type="email"
                                    name="email_address"
                                    id="create-email"
                                    placeholder="rich@fish-trak.com"
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="create-mobile-number">Mobile Number*</Label>
                                <Input
                                    type="tel"
                                    name="mobile_number"
                                    id="create-mobile-number"
                                    placeholder="+14151234567"
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="create-avatar">User Avatar*</Label>
                                <Row>
                                    <Col xs="12">
                                        <Input
                                            type="file"
                                            name="image_link"
                                            id="create-avatar"
                                            className="form-control"
                                            onChange={(e) => { setUploadedImage(e.target.files[0]); }}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="create-home-port">Home Port Location*</Label>
                                <Input
                                    type="text"
                                    name="home_port"
                                    id="create-home-port"
                                    placeholder="Toms River"
                                    required
                                />
                            </FormGroup>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label htmlFor="create-home-port-lat">Home Port Latitude*</Label>
                                        <Input
                                            type="number"
                                            name="home_port_lat"
                                            id="create-home-port-lat"
                                            placeholder="Enter Latitude*"
                                            step="any"
                                            required
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label htmlFor="create-home-port-lng">Home Port Longitude*</Label>
                                        <Input
                                            type="number"
                                            name="home_port_lng"
                                            id="create-home-port-lng"
                                            placeholder="Enter Longitude*"
                                            step="any"
                                            required
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <FormGroup>
                                <Label htmlFor="create-user-country">User Country*</Label>
                                <Input
                                    type="select"
                                    name="user_country"
                                    id="create-user-country"
                                    required
                                >
                                    <option value="">Select User Country</option>
                                    <option value="USA">United States</option>
                                    <option value="CAN">Canada</option>
                                    {/* Add more countries as needed */}
                                </Input>
                            </FormGroup>

                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label>Units System*</Label>
                                        <div>
                                            <FormGroup check inline>
                                                <Label check>
                                                    <Input
                                                        type="radio"
                                                        name="units"
                                                        value="true"
                                                        defaultChecked={true}
                                                    />{' '}
                                                    Metric
                                                </Label>
                                            </FormGroup>
                                            <FormGroup check inline>
                                                <Label check>
                                                    <Input
                                                        type="radio"
                                                        name="units"
                                                        value="false"
                                                        defaultChecked={false}
                                                    />{' '}
                                                    Imperial
                                                </Label>
                                            </FormGroup>
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <FormGroup>
                                <Label>Permissions*</Label>
                                <div>
                                    <FormGroup check inline>
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                name="admin_access"
                                            />{' '}
                                            Admin Access
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check inline>
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                name="partner_access"
                                                onChange={handleCreateCheckboxChange}
                                            />{' '}
                                            Partner Access
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check inline>
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                name="privacy_mode"
                                            />{' '}
                                            Privacy Mode
                                        </Label>
                                    </FormGroup>
                                </div>
                            </FormGroup>

                            {partnerAccessCreate && (
                                <FormGroup>
                                    <Label htmlFor="create-tag-program">Partner Tag Program*</Label>
                                    <Input
                                        type="select"
                                        name="tag_program"
                                        id="create-tag-program"
                                        required
                                    >
                                        <option value="">Select Tag Program</option>
                                        {allTagPrograms.map((prog) => (
                                            <option key={prog.id} value={prog.id}>
                                                {prog.name}
                                            </option>
                                        ))}
                                    </Input>
                                </FormGroup>
                            )}
                        </ModalBody>
                        <ModalFooter>
                            <div className="hstack gap-2 justify-content-end">
                                <Button
                                    type="button"
                                    color="secondary"
                                    onClick={closeCreateModal}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    color="success"
                                    disabled={isUploading}
                                >
                                    Create
                                </Button>
                            </div>
                        </ModalFooter>
                    </form>
                ) : (
                    <ModalBody>
                        <div className="d-flex justify-content-center my-5">
                            <ThreeCircles
                                visible={isCreating}
                                height="100"
                                width="100"
                                color="#4192C3"
                                ariaLabel="three-circles-loading"
                                wrapperStyle={{ margin: "0 auto" }}
                                wrapperClass="justify-content-center"
                            />
                        </div>
                    </ModalBody>
                )}
            </Modal>

            {/* Activate Modal */}
            <Modal isOpen={activateModal} toggle={closeActivateModal} centered>
                {!isActivating ? (
                    <ModalBody>
                        <div className="mt-2 text-center">
                            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                                <h4>Activate User's Account</h4>
                                <p className="text-muted mx-4 mb-0">Are you sure you want to activate this user?</p>
                            </div>
                        </div>
                        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                            <Button
                                type="button"
                                color="light"
                                onClick={closeActivateModal}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="button"
                                color="success"
                                onClick={enableUser}
                            >
                                Activate
                            </Button>
                        </div>
                    </ModalBody>
                ) : (
                    <ModalBody>
                        <div className="d-flex justify-content-center my-5">
                            <ThreeCircles
                                visible={isActivating}
                                height="100"
                                width="100"
                                color="#4192C3"
                                ariaLabel="three-circles-loading"
                                wrapperStyle={{ margin: "0 auto" }}
                                wrapperClass="justify-content-center"
                            />
                        </div>
                    </ModalBody>
                )}
            </Modal>

            {/* Add Modal (Preserved Existing Capability) */}
            <Modal isOpen={modal_list} toggle={() => setmodal_list(!modal_list)} centered>
                <ModalHeader toggle={() => setmodal_list(!modal_list)}>Add User</ModalHeader>
                <form className="tablelist-form" onSubmit={(e) => e.preventDefault()}>
                    <ModalBody>
                        <FormGroup>
                            <Label htmlFor="firstname-field">First Name*</Label>
                            <Input
                                type="text"
                                id="firstname-field"
                                name="first_name"
                                className="form-control"
                                placeholder="Richard"
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="lastname-field">Last Name*</Label>
                            <Input
                                type="text"
                                id="lastname-field"
                                name="last_name"
                                className="form-control"
                                placeholder="Ryan"
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="email-field">Email Address*</Label>
                            <Input
                                type="email"
                                id="email-field"
                                name="email_address"
                                className="form-control"
                                placeholder="rich@fish-trak.com"
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="homeport-field">Home Port*</Label>
                            <Input
                                type="text"
                                id="homeport-field"
                                name="home_port"
                                className="form-control"
                                placeholder="Toms River"
                                required
                            />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <Button
                                type="button"
                                color="light"
                                onClick={() => setmodal_list(false)}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                color="success"
                                disabled
                            >
                                Add User
                            </Button>
                        </div>
                    </ModalFooter>
                </form>
            </Modal>
        </React.Fragment>
    );

};

export default DisabledUsers;